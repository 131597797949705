<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Archived Downloads</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Archived Downloads"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              depressed
              light
              :to="{ name: 'module-grogreen-articles-downloads' }"
            >
              <v-icon small left>mdi-arrow-left</v-icon> Back to Downloads
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="articles"
            no-data-text="No Archived Downloads found"
          >
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    pageTitle: String,
    parentRouteName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "groGreen",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Articles",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Fie", value: "file" },
      ],
    };
  },

  mounted() {
    this.breadcrumbs.push(
      {
        text: this.pageTitle,
        disabled: false,
        exact: true,
        to: {
          name: this.parentRouteName,
        },
      },
      {
        text: "Archived",
        disabled: true,
      }
    );
  },

  computed: {
    articles() {
      let articles = this.$store.state.grogreen.articles["downloadsArchived"];

      if (this.searchTerm !== "") {
        articles = articles.filter((a) => {
          const name = a.name.toLowerCase();

          const searchTerm = this.searchTerm.toLowerCase();
          return name.includes(searchTerm);
        });
      }

      return articles;
    },
  },
};
</script>
